'use strict'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// Import jQuery
import * as $ from 'jquery'

$(document).ready(function () {
  // Navigation Scrollspy
  $(window).bind('scroll load', function () {
    if ($(this).scrollTop() < 80) {
      $('.js-navbar-fixed').addClass('peak-top');
    }
    if ($(this).scrollTop() > 80) {
      $('.js-navbar-fixed').css({
        background: '#F5F9FE'
      });
    } else {
      $('.js-navbar-fixed').css({
        background: ''
      });
    }
  });

  // Navbar Initialization
  function navbar() {
    // Toggle open/close the navbar menu
    $('.js-navbar__menu').on('click', function () {
      $(this).css({ display: 'none' });
      $('.js-navbar__close').css({ display: 'block' });
      $('.navbar__links').css({ display: 'flex' });
      $('.navbar__sign').css({ display: 'flex' });
      $('.js-navbar__links').slideDown(500);
    });
    $('.js-navbar__close').on('click', function () {
      $(this).css({ display: 'none' });
      $('.js-navbar__menu').css({ display: 'block' });
      $('.navbar__links').css({ display: 'none' });
      $('.navbar__sign').css({ display: 'none' });
      $('.js-navbar__links').slideUp(500);
    });

    // Dropdown
    // --- Desktop
    $('#js-dropdown-navbar').click(function () {
      let menuSelect = $('#js-dropdown-menu');
      let navSelect = $('#js-dropdown-navbar');
      let conditionActive = navSelect.hasClass('active1');
      let mouseLeaveMenu = menuSelect.mouseleave(function () {
        navSelect.removeClass('active1');
        $(this).slideUp({
          duration: 500,
        });
      });

      conditionActive | mouseLeaveMenu
        ? ($(this).removeClass('active1'),
          menuSelect.slideUp({
            duration: 500,
          }))
        : ($(this).addClass('active1'),
          menuSelect.slideDown({
            duration: 500,
            start: function () {
              $(this).css('display', 'flex');
            },
          }));
    });

    // --- Mobile
    $('#js-dropdown').click(function () {
      $('#js-dropdown2, #js-dropdown-arrow2, .navbar__sign').toggleClass('active');
    });
  }

  function init() {
    navbar();
  }

  init();
});
